<template>
  <div>
    <p>{{ displayContent }}</p>
    <div class="mb-2">
      <v-btn
        rounded
        small
        depressed
        dark
        color="cyan"
        class="d-inline-block"
        @click="isExpanded = !isExpanded"
        v-if="showView"
      >
        {{ isExpanded ? 'Show Less' : 'View' }}
      </v-btn>
      <!-- <v-btn
        rounded
        small
        depressed
        dark
        class="mx-1 mb-2"
      >
        Delete
      </v-btn> -->
      <delete-item-dialog
        :itemID="itemId"
        :setup="setupDeleteDialog"
        :deleteURL="componentURL"
        :event-name="eventName"
        class="d-inline-block"
      >
      </delete-item-dialog>
    </div>
  </div>
</template>

<script>
// import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";

const DeleteItemDialog = () =>
  import("@/components/miscellaneous/dialogs/DeleteItemDialog.vue");

export default {
  components: {
    DeleteItemDialog,
  },
  props: {
    content: {
      type: String,
      default: null
    },
    itemId: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    showView() {
      if (this.displayContent && this.displayContent.length >= this.maxStringLength) return true;

      return false;
    },
    displayContent() {
      if (!this.isExpanded) {
        if (this.content && this.content.length >= this.maxStringLength)
          return `${this.content.substr(0, this.maxStringLength)} ...`;
      }

      return this.content;
    }
  },
  data: () => ({
    maxStringLength: 150,
    isExpanded: false,
    setupDeleteDialog: {
      button: {
        rounded: true,
        small: true,
        depressed: true,
        dark: true,
        class: "mx-1",
        icon: false,
        label: "Delete",
        isIcon: false,
      },
      card: {
        title: "Devotionals Delete Item",
      }
    },
    eventName: "reloadItems_announcements",
    componentURL: "api/announcements",
  }),
  methods: {
    checkString(value) {
      if (value && value.length >= this.maxStringLength) {
        return true;
      }

      return false;
    }
  }
}
</script>